<template>
  <div>
    <b-card bg-variant="Default" title="Lista Utenti - GoodTable">
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
        <b-form-group>
            <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
            />
            </div>
        </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"

        @on-row-click="onRowClick"
        >
        <template
            slot="table-row"
            slot-scope="props"
        >

            <!-- Column: Name -->
            <div
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
            >
            <b-avatar
                :src="props.row.avatar"
                class="mx-1"
            />
            <span class="text-nowrap">{{ props.row.fullName }}</span>
            </div>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
            </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
            <span>
                <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                >
                <template v-slot:button-content>
                    <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                    />
                </template>
                <b-dropdown-item>
                    <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                    />
                    <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                    <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                    />
                    <span>Delete</span>
                </b-dropdown-item>
                </b-dropdown>
            </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Showing 1 to
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
            
    </b-card>

    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <div class="text-center">
        <b-avatar
          size="72px"
          class="mr-1"
          :src="`${infoSideBar.avatar}`"
        />

        <b-card-text class="mt-2 h4 color-inherit text-reset">
          {{ infoSideBar.fullName }}
        </b-card-text>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BCard, 
    BCardText, 
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Email',
          },
        },
        {
          label: 'Date',
          field: 'startDate',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Date',
          },
        },
        {
          label: 'Salary',
          field: 'salary',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Salary',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      infoSideBar: {
        id: '',
        fullName: '',
        avatar: '',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get('/good-table/basic')
      .then(res => { 
        
        console.log("chiamata....")
        console.log(res.data)

        this.rows = res.data 
      })
  },
  methods: {
    onRowClick(params) {
      // params.row - row object 
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
      /*
      console.log(params.row)
      console.log(params.event)
      */
      
      console.log("apertura SIDEBAR -> "+params.row.id+" -> "+params.row.fullName)

      this.infoSideBar = { id: params.row.id,
                fullName: params.row.fullName,
                avatar: params.row.avatar, };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>